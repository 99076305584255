const initialState = {
	dbUploadFile: null,
	dbUploadedFileResponse: null,
	fileName: '',
	sheetName: '',
	dataSourceList: [],
	dataSourceLoader: false,
	datasourceName: '',
	dataMapping: {
		status: 'loading',
		error: null,
		rowHeader: [],
		rowData: [],
		columnTypeList: [],
		dataTypeList: []
	},
	previewDataMapping: {
		loading: false,
		error: null,
		rowHeader: [],
		rowData: [],
		errorList: [],
		columnCount: 0,
		dataCount: 0
	},
	// Already created database list
	fetchedDatabaseList: [],
};

const reducer = (state = initialState, action) => {
	let newState = JSON.parse(JSON.stringify(state));
	switch (action.type) {
		case 'UPDATE_NEW_DATA_REPORT_DATA':
			newState[action.payload.key] = action.payload.value;
			return newState;
		case 'UPDATE_UPLOAD_FILE_RESPONSE':
			newState.dbUploadedFileResponse = action.payload;
			newState.sheetName =
				Array.isArray(action.payload?.details?.sheets) &&
				action.payload?.details?.sheets.length > 0
					? action.payload?.details?.sheets[0]
					: '';
			return newState;
		case 'UPDATE_DATA_MAPPING_STATUS':
			newState.dataMapping.status = action.payload;
			return newState;
		case 'UPDATE_DATA_MAPPING_DATA':
			newState.dataMapping = action.payload;
			return newState;
		case 'FETCH_DATA_SOURCE_LIST_LOADER':
			newState.dataSourceLoader = true;
			return newState;
		case 'UPDATE_EXCEL_DATA_MAPPING':
			console.log('action.data', action)
			newState.dataMapping.rowData = action.data;
			return newState;
		case 'FETCH_DATA_SOURCE_LIST_SUCCESS':
			newState.dataSourceList = action.data;
			newState.dataSourceLoader = false;
			return newState;
		case 'PREVIEW_EXCEL_DATA_LOADER':
			newState.previewDataMapping.rowData = [];
			newState.previewDataMapping.rowHeader = [];
			newState.previewDataMapping.errorList = [];
			newState.previewDataMapping.dataCount = 0;
			newState.previewDataMapping.columnCount = 0;
			newState.loading = true;
			return newState;
		case 'PREVIEW_EXCEL_DATA_SUCCESS':
			newState.previewDataMapping.rowData = action.data.row_data;
			newState.previewDataMapping.rowHeader = action.data.row_header;
			newState.previewDataMapping.errorList = action.data.error_list;
			newState.previewDataMapping.dataCount = action.data.data_count;
			newState.previewDataMapping.columnCount = action.data.column_count;
			newState.loading = false;
			return newState;
		default:
			return newState;
	}
};

export default reducer;
