import axios from 'axios';
import { CONFIG } from '../../../../config';

export async function fetchDataSourceListService() {
	return axios
		.get(`${CONFIG.api_base_url}ds/getdatasourcelist`)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}


export async function previewExcelDataService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}ds/previewexcel`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function saveExcelDataService(payload) {
	return axios
		.post(`${CONFIG.api_base_url}ds/saveexceldata`, payload)
		.then((response) => {
			return response;
		})
		.catch((err) => {
			return err.response;
		});
}

export async function getDataMappingDataFromAPI(payload) {
	return axios
		.post(`${CONFIG.api_base_url}ds/getexcelmapping`, payload)
		.then((res) => res)
		.catch((err) => err.response);
}