// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./SCProsperSans-Light.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./SCProsperSans-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./SCProsperSans-Medium.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./SCProsperSans-Bold.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\n    font-family: 'SCProsperSans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    font-weight: 100;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'SCProsperSans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n    font-weight: 300;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'SCProsperSans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n    font-weight: normal;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'SCProsperSans';\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ");\n    font-weight: bold;\n    font-style: italic;\n}", "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,4CAAmC;IACnC,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,4CAAqC;IACrC,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,4CAAoC;IACpC,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,4BAA4B;IAC5B,4CAAkC;IAClC,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":["@font-face {\n    font-family: 'SCProsperSans';\n    src: url(./SCProsperSans-Light.ttf);\n    font-weight: 100;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'SCProsperSans';\n    src: url(./SCProsperSans-Regular.ttf);\n    font-weight: 300;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'SCProsperSans';\n    src: url(./SCProsperSans-Medium.ttf);\n    font-weight: normal;\n    font-style: italic;\n}\n\n@font-face {\n    font-family: 'SCProsperSans';\n    src: url(./SCProsperSans-Bold.ttf);\n    font-weight: bold;\n    font-style: italic;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
