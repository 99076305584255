import { toastr } from 'react-redux-toastr';
import { call, delay, put, takeLatest } from 'redux-saga/effects';
import {
	fetchDataSourceListService,
	previewExcelDataService,
	saveExcelDataService,
	getDataMappingDataFromAPI
} from './service';


function* fetchDataSourceList(data) {
	yield put({ type: 'FETCH_DATA_SOURCE_LIST_LOADER', loading: true });
	const response = yield call(fetchDataSourceListService, data.payload);
	if (response && response.status == 200) {
		yield put({
			type: 'FETCH_DATA_SOURCE_LIST_SUCCESS',
			data: response.data,
		});
	}
}

function* getDataMappingData(data) {
	yield put({ type: 'UPDATE_DATA_MAPPING_STATUS', payload: 'loading' });
	const response = yield call(getDataMappingDataFromAPI, data.payload);
	if (response && response.status == 200) {
		console.log(response, 'getDataMappingData')

		const {
			column_type_list = [],
			data_type_list = [],
			row_data = [],
			row_header = [],
		} = response?.data ?? {};

		yield put({
			type: 'UPDATE_DATA_MAPPING_DATA',
			payload: {
				status: 'success',
				error: null,
				rowHeader: Array.isArray(row_header) ? row_header : [],
				rowData: Array.isArray(row_data) ? row_data : [],
				columnTypeList: Array.isArray(column_type_list) ? column_type_list : [],
				dataTypeList: Array.isArray(data_type_list) ? data_type_list : []
			},
		});

		data.navigate(`/create-data-report/data-mapping?id=${data.payload._id}`);
	}else{
		yield put({
			type: 'UPDATE_DATA_MAPPING_DATA',
			payload: {
				status: 'fail',
				error: response,
				rowHeader: [],
				rowData: [],
			},
		});
		toastr.error(response?.data?.error?.debug_msg || response?.error?.debug_msg);
	}
}

function* previewExcelData(data) {
	yield put({ type: 'PREVIEW_EXCEL_DATA_LOADER', loading: true });
	yield put({
		type: 'UPDATE_EXCEL_DATA_MAPPING',
		data: data.payload.data,
	});
	const response = yield call(previewExcelDataService, data.payload);
	if (response && response.status == 200) {
		console.log(response, 'previewExcelData')
		yield put({
			type: 'PREVIEW_EXCEL_DATA_SUCCESS',
			data: response.data,
		});
		data.navigate(`/create-data-report/data-mapping-preview?id=${data.payload._id}`);
	}else{
		toastr.error(response?.data?.error?.debug_msg || response?.error?.debug_msg);
	}
}


function* saveExcelData(data) {
	yield put({ type: 'SAVE_EXCEL_DATA_LOADER', loading: true });
	const response = yield call(saveExcelDataService, data.payload);
	if (response && response.status == 200) {
		console.log(response, 'SAVEExcelData')
		yield put({
			type: 'SAVE_EXCEL_DATA_SUCCESS',
			data: response.data,
		});
		data.navigate(`/data-report?datasource=${data.payload._id}`);

	}else {
		toastr.error(response?.data?.error?.debug_msg || response?.error?.debug_msg);
	}
}


export default function* actionWatcher() {
	yield takeLatest(
		'FETCH_DATA_SOURCE_LIST',
		fetchDataSourceList
	);
	yield takeLatest(
		'GET_DATA_MAPPING_DATA',
		getDataMappingData
	);
	yield takeLatest(
		'PREVIEW_EXCEL_DATA',
		previewExcelData
	);
	yield takeLatest(
		'SAVE_EXCEL_DATA',
		saveExcelData
	);
}
