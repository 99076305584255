import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import AnalyticsStudioReducer from '../components/AnalyticsStudio/store/reducer';
import CreateDataReportReducer from '../components/AnalyticsStudio/CreateDataReport/store/reducer';

export default combineReducers({
	toastr: toastrReducer,
	analyticStudioState: AnalyticsStudioReducer,
	createDataReport: CreateDataReportReducer,
});
