import axios from 'axios';
import {store} from './index';
import {
  addApiRequestIdToList,
  removeApiRequestIdFromList,
} from './helpers/utilActions';

// axios.defaults.baseURL = window['runConfig'].apiBaseUrl;
export const cancelSource = axios.CancelToken.source();

export const createAxiosInterceptor = () => {
  // Add a request interceptor
  axios.interceptors.request.use(config => {
        // Do something before request is sent
        const clientId = config?.headers?.['X-CLIENT-ID'] ?? '';
        if (clientId) {
          store.dispatch(addApiRequestIdToList(clientId));
        }
        config.cancelToken = cancelSource.token;
        return config;
      },
      error => {
        // Do something with request error
        return Promise.reject(error);
      });

  // Add a response interceptor
  axios.interceptors.response.use(
      response => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data

        const clientId = response?.config?.headers?.['X-CLIENT-ID'] ?? '';
        if (clientId) store.dispatch(removeApiRequestIdFromList(clientId));

        return response;
      },
      error => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        const clientId = error?.response?.config?.headers?.['X-CLIENT-ID'] ?? '';
        if (clientId) store.dispatch(removeApiRequestIdFromList(clientId));

        if (error.response.status === 401) {
          const {mainApp: { baseUrl, loginPagePath }} = window['runConfig'];
          window.location.href  = `${baseUrl}${loginPagePath}?redirectUri=${window.location.href}`;
          return Promise.reject(error)
        } else return Promise.reject(error);
      });
};
