import { all } from 'redux-saga/effects';
import AnalyticsStudioSaga from '../components/AnalyticsStudio/store/saga'
import CreateDataReportSaga from '../components/AnalyticsStudio/CreateDataReport/store/saga';

export default function* rootSaga() {
    yield all([
        AnalyticsStudioSaga(),
        CreateDataReportSaga()
    ]);
}
